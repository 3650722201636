import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosBulb, IoIosMan, IoIosStar } from "react-icons/io";
import { BsFillShieldFill } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Canvas Creations
			</title>
			<meta name={"description"} content={"Уявляйте. В чорнило. Надихайте. Завітайте до Canvas Creations, де ваші історії намальовані чорнилом."} />
			<meta property={"og:title"} content={"Canvas Creations"} />
			<meta property={"og:description"} content={"Уявляйте. В чорнило. Надихайте. Завітайте до Canvas Creations, де ваші історії намальовані чорнилом."} />
			<meta property={"og:image"} content={"https://kexonovel.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://kexonovel.com/img/heart.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kexonovel.com/img/heart.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kexonovel.com/img/heart.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kexonovel.com/img/heart.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://kexonovel.com/img/heart.png"} />
			<meta name={"msapplication-TileImage"} content={"https://kexonovel.com/img/heart.png"} />
		</Helmet>
		<Components.Header />
		<Section
			min-height="60vh"
			lg-padding="120px 0 88px 0"
			md-padding="140px 0 96px 0"
			sm-padding="60px 0 96px 0"
			quarkly-title="Hero-23"
			padding="88px 0 88px 0"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.5) 67.71%,rgba(4, 8, 12, 0.6) 100%),url(https://kexonovel.com/img/1.jpg) center center/cover no-repeat"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-flex-direction="column"
				display="flex"
				lg-width="100%"
				align-items="flex-start"
				lg-align-items="center"
				lg-justify-content="center"
				width="100%"
				flex-direction="row"
			/>
			<Box
				margin="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-justify-content="center"
				display="flex"
				lg-align-items="center"
				align-items="flex-end"
				lg-width="100%"
				padding="0px 0px 0px 0px"
				justify-content="space-between"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
			>
				<Text
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="25%"
					md-width="100%"
					margin="0px 0px 0px 0px"
					lg-width="100%"
					color="--light"
					font="--headline2"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					lg-margin="0px 0px 20px 0px"
					lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Тату салон Canvas Creations
				</Text>
				<Text
					color="#FFFFFF"
					font="--base"
					width="45%"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					Тату-салон Canvas Creations запрошує вас у світ, де чорнило та уява плавно зливаються воєдино. Наша студія - це ваш простір для дослідження та вираження ваших історій через красиве, довговічне мистецтво. Наші двері відчинені як для новачків, так і для ентузіастів татуювання, щоб ви могли створити свій наступний твір з точністю та пристрастю.
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-18"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text
					margin="8px 0px 0px 0px"
					font="--lead"
					text-align="center"
					color="--green"
					sm-text-align="left"
				>
					<Strong color="--primary">
						Canvas Creations
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="4px 0px 12px 0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					text-align="center"
					sm-text-align="left"
				>
					Чому Canvas Creations
				</Text>
				<Box
					display="grid"
					width="100%"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					margin="60px 0px 0px 0px"
					max-width="1000px"
					md-grid-template-columns="1fr"
					md-grid-gap="6px"
					sm-grid-gap="32px"
				>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosBulb}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Художня досконалість: 
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Наші художники відомі своєю мистецькою багатогранністю та прискіпливою увагою до деталей.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosMan}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Персоналізований досвід: 
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Ми тісно співпрацюємо з вами, щоб створити дизайн, який відображає вашу індивідуальність і прагнення.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosStar}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Безкомпромісна якість: 
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Використовуючи новітнє обладнання та найвищі стандарти гігієни, ми гарантуємо першокласний досвід татуювання.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="bs"
							icon={BsFillShieldFill}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Розслаблена атмосфера: 
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Наш салон створений для того, щоб ви почувалися невимушено, щоб зробити ваш досвід татуювання настільки ж приємним, наскільки й незабутнім.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});